import { experiments, ExperimentId, getCookieName } from '@sr/experiments';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import {
  appendToMixpanelUser,
  trackEventMixpanel,
  trackHotjarEvent,
} from 'lib/analytics';
import { isDev } from 'lib/env-checks';
import isBrowser from 'lib/is-browser';

export function useTrackExperimentStarted() {
  const expInfo = getExpInfo();

  useEffect(() => {
    // Only send tracking events if there is an experiment to track
    // Only run this hook from the browser (not for server rendering)
    if (expInfo && isBrowser()) {
      const { expId, variant } = expInfo;
      const { name } = experiments[expId];
      // This is Mixpanels built in premium experiment feature
      trackEventMixpanel('$experiment_started', {
        'Experiment name': `(${expId}): ${name}`,
        'Variant name': variant,
      });

      // Also track the experiment manually in case we lose access to premium experiments
      trackEventMixpanel('SR Experiment Started', {
        'Experiment ID': expId,
        'Experiment name': name,
        'Variant name': variant,
      });
      appendToMixpanelUser('Experiments', `${expId}-${variant}`);

      // Send experiment data to Hotjar for easier heatmap/recording filtering
      trackHotjarEvent(`${expId}-started`);

      // Set the experiment cookie to assure same variant is shown next time
      Cookies.set(getCookieName(expId), variant, {
        domain: isDev() ? '' : `.${window.location.hostname}`,
        expires: 90,
      });

      // Clear the special cookie from middleware so the events aren't fired again
      Cookies.remove(EXP_STARTED_COOKIE_NAME);
    }
  }, [expInfo]);
}

export const EXP_STARTED_COOKIE_NAME = 'sr-exp-started';

type ExpInfo = {
  expId: ExperimentId;
  variant: string;
};

function getExpInfo(): ExpInfo | null {
  try {
    const value = Cookies.get(EXP_STARTED_COOKIE_NAME);
    const [expId, variant] = value.split(':');
    return { expId: expId as ExperimentId, variant };
  } catch (error) {
    return null;
  }
}
