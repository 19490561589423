export function isDev() {
  return (
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === undefined
  );
}

export function isStaging() {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
}

export function isProd() {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
}
