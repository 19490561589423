// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://b406e8066b4a4eda9ab7567bbdd02440@o69914.ingest.sentry.io/5500225';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: [
        /^\//,
        'localhost',
        'app.standardresume.co',
        'app.standardresume.dev',
      ],
    }),
  ],
  tracesSampler: (samplingContext) => {
    // Always include include traces with a parent
    if (samplingContext.parentSampled) {
      return 1;
    }
    // Apply lower sampling rates to frequently called or unimportant transactions
    switch (samplingContext?.transactionContext?.name) {
      case '/remote-job/[slug]':
        return 0.01;
      case '/':
        return 0.2;
      case '/previews/share-card/[id]':
      case '/examples/[slug]':
      case '/r/[slug]':
        return 0.1;
      // TODO reduce this once PDF rendering bugs are fixed
      case '/previews/pdf/[id]':
        return 1;
      default:
        return 0.5;
    }
  },
});
