import { parse } from 'query-string';
import { arrayIncludesSubstring } from './array-includes-substring';

export function trackEventMixpanel(
  eventName: string,
  properties: Record<string, string | number>
) {
  try {
    if (!isBot()) {
      const mixpanel = (window as any).mixpanel;
      mixpanel.track(eventName, properties);
    }
  } catch (e) {
    // Do nothing
  }
}

export function trackPageviewMixpanel(pathWithQuery: string) {
  try {
    if (!isBot() && shouldTrack(pathWithQuery)) {
      const mixpanel = (window as any).mixpanel;
      setUtmParams();
      // Remove query part of pathWithQuery
      const path = pathWithQuery.split('?')[0];
      mixpanel.track('Pageview', { path });
    }
  } catch (e) {
    // Do nothing
  }
}

// Append a value to a list-valued people analytics property
export function appendToMixpanelUser(property: string, value: string) {
  try {
    const mixpanel = (window as any).mixpanel;
    if (!isBot()) {
      mixpanel.people.append(property, value);
    }
  } catch (error) {
    // Do nothing
  }
}

export function trackHotjarEvent(eventName: string) {
  try {
    const hj = (window as any).hj;
    if (hj && !isBot()) {
      hj('event', eventName);
    }
  } catch (error) {
    // Do nothing
  }
}

export function identifyHotjarUser(
  userId: string,
  traits: { [key: string]: string }
) {
  try {
    const hj = (window as any).hj;
    if (hj && !isBot()) {
      hj('identify', userId, traits);
    }
  } catch (error) {
    // Do nothing
  }
}

// Returns true if the URL should trigger a pageview. Used to ignore pages
// that are visited by our functions to generate images or PDFs.
function shouldTrack(pathWithQuery: string) {
  return !pathWithQuery.includes('/preview');
}

export const botUserAgents = [
  'bot',
  'crawl',
  'spider',
  'cypress',
  'checkly',
  'percy',
  'cloudflare',
  'headlesschrome',
  'lighthouse',
  'monitoring',
];

function isBot() {
  const userAgent = window.navigator.userAgent;
  return isUserAgentBot(userAgent);
}

export function isUserAgentBot(userAgent: string): boolean {
  return arrayIncludesSubstring(botUserAgents, userAgent.toLowerCase());
}

// Add utm params to super properties for app to pick up
function setUtmParams() {
  try {
    const mixpanel = (window as any).mixpanel;
    const utmParams = getUtmParams();
    if (Object.keys(utmParams).length > 0) {
      mixpanel.register(utmParams);
    }
  } catch (error) {
    // Do nothing
  }
}

type UtmParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_name?: string;
  utm_campaign?: string;
  utm_adg?: string;
};

// Gets UTM parameters from current URL
function getUtmParams(): UtmParams {
  const keys: (keyof UtmParams)[] = [
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_name',
    'utm_campaign',
    'utm_adg',
  ];
  const params: UtmParams = {};
  const search = window.location.search;
  if (!search) return params;
  const parsed = parse(search);
  keys.forEach((key) => {
    const value = parsed[key];
    if (value && !Array.isArray(value)) {
      params[key] = value;
    }
  });
  return parsed;
}
