type Colors = {
  // Dynamic
  text: string;
  textLight: string;
  textLighter: string;
  textLightest: string;
  textInverted: string;

  background: string;
  background80: string;
  backgroundGray: string;
  backgroundBlue: string;
  backgroundGreen: string;
  brandBlue: string;
  brandBlue10: string;
  buttonGray: string;
  error: string;
  success: string;
  pink: string;

  // Static
  sBrandBlue: string;
  sWhite: string;
  sBlack: string;
  sButtonGrayDark: string;
  sButtonGrayLight: string;
};

// Colors that don't change when in dark mode
const staticColors = {
  sWhite: 'rgba(255, 255, 255, 1)',
  sBlack: 'rgba(0, 0, 0, 1)',
  sBrandBlue: 'rgba(42, 63, 251, 1)',
  sButtonGrayDark: 'rgba(36, 37, 45, 1)',
  sButtonGrayLight: 'rgba(228, 235, 241, 1)',
};

// Dynamic color values for light mode
export const lightColors: Colors = {
  text: 'rgba(0, 0, 0, 1)',
  textLight: 'rgba(0, 0, 0, 0.8)',
  textLighter: 'rgba(0, 0, 0, 0.6)',
  textLightest: 'rgba(0, 0, 0, 0.5)',
  textInverted: 'rgba(255, 255, 255, 1)',

  background: 'rgba(242, 247, 252, 1)',
  background80: 'rgba(242, 247, 252, 0.8)',
  backgroundGray: 'rgba(0, 0, 0, 0.05)',
  backgroundGreen: 'rgba(225, 245, 241, 1)',
  backgroundBlue: 'rgba(236,239,251,1)',
  brandBlue: 'rgba(42, 63, 251, 1)',
  brandBlue10: 'rgba(42, 63, 251, 0.1)',
  buttonGray: 'rgba(228, 235, 241, 1)',
  error: 'rgba(251, 16, 100, 1)',
  success: 'rgba(0, 172, 83, 1)',
  pink: 'rgba(251, 47, 255, 1)',

  ...staticColors,
};

// Dynamic color values for dark mode
export const darkColors: Colors = {
  text: 'rgba(255, 255, 255, 1)',
  textLight: 'rgba(255, 255, 255, 0.8)',
  textLighter: 'rgba(255, 255, 255, 0.6)',
  textLightest: 'rgba(255, 255, 255, 0.4)',
  textInverted: 'rgba(0, 0, 0, 1)',

  background: 'rgba(0, 0, 0, 1)',
  background80: 'rgba(0, 0, 0, 0.8)',
  backgroundGray: 'rgba(0, 0, 0, 0.8)',
  backgroundGreen: 'rgba(225, 245, 241, 1)',
  backgroundBlue: 'rgba(236,239,251,1)',
  brandBlue: 'rgba(89, 175, 255, 1)',
  brandBlue10: 'rgba(89, 175, 255, 0.1)',
  buttonGray: 'rgba(36, 37, 45, 1)',
  error: 'rgba(251, 16, 100, 1)',
  success: 'rgba(67, 225, 143, 1)',
  pink: 'rgba(251, 47, 255, 1)',

  ...staticColors,
};
