import { useRouter } from 'next/router';

// Gets our default canonical URL
// Which is the current href minus path and hash
export default function useCanonical() {
  const router = useRouter();
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}${router.asPath}`;
  // Remove query string and hashes
  const canonicalUrl = url.split('?')[0].split('#')[0];
  return canonicalUrl;
}
