// Experiment ID should match the Linear issue ID.
// Treat id and variant as IDs. Use underscores instead of spaces.
export const experiments = {
  exp5: {
    id: 'exp5',
    name: 'Skip account creation during setup',
    variants: ['control', 'skip_account_creation'],
  },
  exp34: {
    id: 'exp34',
    name: 'Minimal header on homepage',
    variants: ['control', 'minimal_header'],
  },
  exp38: {
    id: 'exp38',
    name: 'Template customization restrictions',
    variants: ['control', 'restricted'],
  },
  exp39: {
    id: 'exp39',
    name: 'Product focused template pages',
    variants: ['control', 'ecom', 'grid'],
  },
  exp42: {
    id: 'exp42',
    name: 'Magic Fill setup',
    variants: ['control', 'magic'],
  },
} as const;
type Experiments = typeof experiments;

export type ExperimentId = keyof typeof experiments;
export type Variants<T extends ExperimentId> =
  Experiments[T]['variants'][number];

// Randomly select a variant for the experiment
export function chooseVariant<T extends ExperimentId>(
  experimentId: T
): Variants<T> {
  const variants = experiments[experimentId].variants;
  return chooseRandom(variants);
}

const COOKIE_BASE_NAME = 'sr-';

export function getCookieName(id: ExperimentId) {
  return `${COOKIE_BASE_NAME}${id}`;
}

// Choose a random value from the array. Copied from Lodash.
function chooseRandom<T>(array: Readonly<T[]>): T {
  const length = array == null ? 0 : array.length;
  if (length === 0) {
    throw new Error("Can't choose a random element from an empty array");
  }
  return array[Math.floor(Math.random() * length)];
}
