import { trackPageviewMixpanel } from 'lib/analytics';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ThemeProvider } from 'theme-ui';
import DefaultMetadata from 'components/seo/DefaultMetadata';
import { useTrackExperimentStarted } from 'hooks/use-experiment-started';
import theme from 'styles/theme';

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // Send tracking events when an experiment has started
  useTrackExperimentStarted();

  useEffect(() => {
    // `routeChangeComplete` won't run for the first page load unless the query string is
    // hydrated later on, so here we log a page view if this is the first render and
    // there's no query string
    if (!router.asPath.includes('?')) {
      trackPageviewMixpanel(router.asPath);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (pathWithQuery: string) => {
      trackPageviewMixpanel(pathWithQuery);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThemeProvider theme={theme}>
      <DefaultMetadata />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}
