import { Theme, ThemeUICSSProperties } from 'theme-ui';
import { lightColors } from './colors';

// Constants for link/button hover effects
const hoverTransition = 'all 0.2s linear';
const hoverScale = 'scale(1.05)';
const hoverFilter = 'brightness(110%)';

// Spacing
const gridGap = [16, 20, 24];
const gridItemPadding = [24, 48, 48];
const contentPadding = [
  // gridGap[0] + gridItemPadding[0],
  gridGap[0],
  gridGap[1] + gridItemPadding[1],
  gridGap[2] + gridItemPadding[2],
];

// Grid and container max widths
// Add padding to width so content stays at 980px (+padding, +margin)
const mobileMaxWidth = 500;
const contentMaxWidth = [
  mobileMaxWidth,
  980 + contentPadding[1] * 2,
  980 + contentPadding[2] * 2,
];
const slimMaxWidth = [
  mobileMaxWidth,
  628 + contentPadding[1] * 2,
  628 + contentPadding[2] * 2,
];
const gridMaxWidth = [500, 1440, 1440];

export const sizes = {
  gridGap,
  gridItemPadding,
  contentPadding,
  contentMaxWidth,
  slimMaxWidth,
  gridMaxWidth,
  mobileMaxWidth,
};

export const fontSizes = {
  displayLarge: [24, 36, 48],
  displayMedium: [22, 32, 40],
  displaySmall: [20, 28, 32],
  displayXSmall: [18, 20, 24],
  jumbo: [22, 24, 28],
  title: [16, 16, 18],
  article: [14, 15, 16],
  default: [14, 14, 15],
  caption: [11, 11, 12],
};

export type TextStyles = {
  default: ThemeUICSSProperties;
  article: ThemeUICSSProperties;
  emphasis: ThemeUICSSProperties;
  title: ThemeUICSSProperties;
  titleEmphasis: ThemeUICSSProperties;
  caption: ThemeUICSSProperties;
  captionEmphasis: ThemeUICSSProperties;
  jumbo: ThemeUICSSProperties;
  heading: ThemeUICSSProperties;
  displayLarge: ThemeUICSSProperties;
  displayMedium: ThemeUICSSProperties;
  displaySmall: ThemeUICSSProperties;
  displayXSmall: ThemeUICSSProperties;
};

export const text: TextStyles = {
  default: {
    color: 'text',
    fontSize: fontSizes.default,
    fontWeight: 'body',
    lineHeight: 'body',
  },
  article: {
    color: 'text',
    fontSize: fontSizes.article,
    fontWeight: 'body',
    lineHeight: 'body',
  },
  emphasis: {
    color: 'text',
    fontSize: fontSizes.default,
    fontWeight: 'bold',
    lineHeight: 'body',
  },
  title: {
    color: 'text',
    fontSize: fontSizes.title,
    fontWeight: 'body',
    lineHeight: 'body',
  },
  titleEmphasis: {
    color: 'text',
    fontSize: fontSizes.title,
    fontWeight: 'semibold',
    lineHeight: 'body',
  },
  jumbo: {
    color: 'text',
    fontSize: fontSizes.jumbo,
    fontWeight: 'body',
    lineHeight: 'body',
  },
  caption: {
    color: 'text',
    fontSize: fontSizes.caption,
    fontWeight: 'body',
    lineHeight: 'body',
  },
  captionEmphasis: {
    color: 'text',
    fontSize: fontSizes.caption,
    fontWeight: 'bold',
    lineHeight: 'body',
  },
  heading: {
    color: 'text',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  displayLarge: {
    color: 'text',
    fontSize: fontSizes.displayLarge,
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  displayMedium: {
    color: 'text',
    fontSize: fontSizes.displayMedium,
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  displaySmall: {
    color: 'text',
    fontSize: fontSizes.displaySmall,
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  displayXSmall: {
    color: 'text',
    fontSize: fontSizes.displayXSmall,
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
};

const theme: Theme = {
  useBorderBox: true,
  useCustomProperties: false,

  breakpoints: ['768px', '1024px', '1280px', '1400px'],

  fonts: {
    body: `Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    heading: 'inherit',
    monospace: '"IBM Plex Mono", monospace',
  },

  fontWeights: {
    body: 400,
    medium: 500,
    semibold: 600,
    heading: 700,
    bold: 700,
  },

  fontSizes: {},

  space: [],

  colors: lightColors,

  lineHeights: {
    heading: 1.13,
    body: 1.5,
  },

  radii: {
    small: 8,
    large: 24,
  },

  zIndices: {
    topContent: 50,
    header: 100,
    drawer: 500,
    modal: 1000,
  },

  text,

  buttons: {
    // The theme-ui Button component also adds styles
    // https://github.com/system-ui/theme-ui/blob/master/packages/components/src/Button.js
    primary: {
      fontFamily: 'body',
      display: 'inline-block',
      color: 'textInverted',
      backgroundColor: 'brandBlue',
      fontWeight: 'bold',
      px: 16,
      py: 16,
      outline: 'none',
      textDecoration: 'none',
      lineHeight: 1,
      cursor: 'pointer',
      borderRadius: 'small',
      transition: hoverTransition,
      ':disabled': {
        bg: 'buttonGray',
        cursor: 'not-allowed',
      },
      ':hover': {
        '@media (hover: hover)': {
          transform: hoverScale,
          boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.25)',
          filter: hoverFilter,
        },
      },
      ':active, :disabled:hover': {
        '@media (hover: hover)': {
          transform: 'scale(1)',
          boxShadow: 'none',
          filter: 'brightness(100%)',
        },
      },
    },
    primaryHollow: {
      variant: 'buttons.primary',
      color: 'brandBlue',
      bg: 'transparent',
      borderColor: 'brandBlue',
      borderStyle: 'solid',
      borderWidth: 1,
      '@media (hover: hover)': {
        ':hover': {
          boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.15)',
        },
      },
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'text',
      backgroundColor: 'buttonGray',

      '@media (hover: hover)': {
        ':hover': {
          boxShadow: '0 20px 20px -20px rgba(0, 0, 0, 0.10)',
          filter: 'brightness(101%)',
        },
      },
    },
    withIcon: {
      variant: 'buttons.primary',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: 8,

      '@media (hover: hover)': {
        ':hover': {
          transform: hoverScale,
          boxShadow: '0 20px 15px -20px rgba(0, 0, 0, 0.25)',
          filter: hoverFilter,
        },
        ':active, :disabled:hover': {
          '@media (hover: hover)': {
            transform: 'scale(1)',
            boxShadow: 'none',
            filter: 'brightness(100%)',
          },
        },
      },
    },
    withIconSecondary: {
      variant: 'buttons.secondary',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: 8,
    },
    withIconHollow: {
      variant: 'buttons.primaryHollow',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: 8,
    },
    icon: {
      variant: 'buttons.primary',
      backgroundColor: 'transparent',
      '@media (hover: hover)': {
        ':hover': {
          transform: hoverScale,
          boxShadow: 'none',
        },
      },
    },
    unstyled: {
      bg: 'transparent',
      color: 'text',
      textDecoration: 'none',
      border: 'none',
      outline: 'none',
      appearance: 'none',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      fontFamily: 'body',
      cursor: 'pointer',
    },
  },

  links: {
    default: {
      display: 'inline-flex',
      color: 'brandBlue',
      textDecoration: 'none',
      transition: hoverTransition,
      '@media (hover: hover)': {
        ':hover': {
          textDecoration: 'none',
          transform: hoverScale,
          filter: 'brightness(140%)',
        },
        ':active': {
          transform: 'scale(1)',
        },
      },
    },
    inherit: {
      color: 'inherit',
      textDecoration: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    arrow: {
      variant: 'links.default',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    unstyled: {
      textDecoration: 'none',

      '@media (hover: hover)': {
        ':hover': {
          textDecoration: 'none',
          transform: 'none',
        },
      },
    },
    primaryButton: { variant: 'buttons.primary' },
    primaryButtonHollow: { variant: 'buttons.primaryHollow' },
    secondaryButton: { variant: 'buttons.secondary' },
    secondaryButtonHollow: { variant: 'buttons.secondaryHollow' },
    buttonWithIcon: { variant: 'buttons.withIcon' },
    buttonWithIconSecondary: { variant: 'buttons.withIconSecondary' },
    buttonWithIconHollow: { variant: 'buttons.withIconHollow' },
  },

  layout: {
    content: {
      maxWidth: sizes.contentMaxWidth,
      mx: 'auto',
      px: sizes.contentPadding,
    },
    gridContent: {
      maxWidth: sizes.gridMaxWidth,
      mx: 'auto',
      px: sizes.contentPadding,
    },
    slim: {
      maxWidth: sizes.slimMaxWidth,
      mx: 'auto',
      px: sizes.contentPadding,
    },
  },

  grids: {
    full: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: sizes.gridGap,
      px: sizes.gridGap,
      maxWidth: sizes.gridMaxWidth,
      marginX: 'auto',
    },
    // full with gridItemPadding added to paddingX
    // This is aligning grids without cards to content in card grids
    fullPadded: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: sizes.gridGap,
      px: sizes.contentPadding,
      maxWidth: sizes.gridMaxWidth,
      marginX: 'auto',
    },
    item: {
      p: sizes.gridItemPadding,
    },
    itemX: {
      px: sizes.gridItemPadding,
    },
    itemY: {
      py: sizes.gridItemPadding,
    },
  },

  forms: {
    input: {
      fontFamily: 'body',
    },
    textarea: {
      fontFamily: 'body',
    },
  },

  styles: {
    root: {
      fontFamily: 'body',
      variant: 'text.default',
      margin: 0,
      backgroundColor: 'background',

      // Font/text settings from https://rsms.me/inter/ css
      fontFeatureSettings: "'case' 1, 'rlig' 1, 'calt' 1, 'kern' 1",
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      fontVariantLigatures: 'contextual common-ligatures',
      fontKerning: 'normal',
    },

    p: {
      variant: 'text.article',
      mt: '0.8em',
    },
    a: {
      color: 'brandBlue',
      fontWeight: 'medium',
      textDecoration: 'none',
      opacity: 1,
      transition: '300ms',
      '@media (hover: hover)': {
        ':hover': {
          opacity: 0.7,
        },
        ':active': {
          opacity: 1,
        },
      },
    },
    h1: {
      variant: 'text.heading',
      fontSize: ['1.6rem', '2rem'],
      mt: '1.8em',
      mb: '0.5em',
    },
    h2: {
      variant: 'text.heading',
      fontSize: ['1.4rem', '1.75rem'],
      mt: '1.8em',
      mb: '0.5em',
    },
    h3: {
      variant: 'text.heading',
      fontSize: ['1.3rem', '1.4rem'],
      mt: '1.8em',
      mb: '0.5em',

      display: 'grid',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      gridTemplateColumns: 'auto minmax(0, 1fr)',
      gridGap: 20,
      '::after': {
        content: '""',
        borderTop: '1px solid',
        borderColor: 'buttonGray',
      },
    },
    h4: {
      variant: 'text.heading',
      fontSize: ['1.2rem', '1.3rem'],
      mt: '1.8em',
      mb: '0.5em',
    },
    h5: {
      variant: 'text.heading',
      fontSize: '1.1rem',
      mt: '1.8em',
      mb: '0.5em',
    },
    strong: {
      fontWeight: 'bold',
    },
    em: {
      fontStyle: 'italic',
    },
    code: {
      variant: 'text.article',
      fontFamily: 'monospace',
    },
    ul: {
      bg: 'backgroundBlue',
      borderRadius: 'small',
      py: '1em',
      pr: '1em',
      unstyled: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
      },
    },
    ol: {
      variant: 'styles.ul',
    },
    li: {
      variant: 'text.article',
      mb: '0.7em',
      ':last-child': {
        mb: 0,
      },
    },
    hr: {
      border: 'none',
      height: 1,
      bg: 'buttonGray',
      my: '4em',
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: 'small',
      my: '0.5rem',
    },
    blockquote: {
      position: 'relative',
      variant: 'text.jumbo',
      fontSize: [18, 20, 24],
      bg: 'backgroundBlue',
      color: 'brandBlue',
      borderRadius: 'small',
      mx: 0,
      px: '1rem',
      pl: '2rem',
      py: '2rem',
      ':before': {
        position: 'absolute',
        top: 28,
        left: 18,
        color: 'brandBlue',
        content: 'open-quote',
        fontSize: [18, 20, 24],
      },
      ':after': {
        color: 'brandBlue',
        content: 'close-quote',
        fontSize: [18, 20, 24],
        display: 'inline',
        pl: 6,
      },
    },
    well: {
      blue: {
        bg: 'backgroundBlue',
        borderRadius: 'small',
        py: '1em',
        px: '1em',
        my: '1em',
        title: {
          variant: 'styles.well.title',
          color: 'brandBlue',
        },
      },
      green: {
        variant: 'styles.well.blue',
        bg: 'backgroundGreen',
        title: {
          variant: 'styles.well.title',
          color: 'success',
        },
      },
      title: {
        mb: '1em',
        fontWeight: 'medium',
        fontFamily: 'monospace',
        textTransform: 'uppercase',
        color: 'brandBlue',
        letterSpacing: '0.05em',
      },
    },
    relatedArticle: {
      display: 'flex',
      flexDirection: ['column', 'row'],
      borderRadius: 'small',
      bg: 'backgroundBlue',
      overflow: 'hidden',
      cursor: 'pointer',
      my: '2em',
      transition: 'opacity 200ms',
      ':hover': {
        opacity: 0.6,
      },
      '> img': {
        width: ['100%', 220],
        height: 'auto',
        flexShrink: 0,
        objectFit: 'cover',
      },
      '> div': {
        flex: '1 1',
        p: '1em',
      },
      title: {
        variant: 'styles.h4',
        my: 0,
      },
      // @ts-ignore - Missing type for WebkitBoxOrient but it works...
      description: {
        mt: '1em',
        mb: '1em',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      p: 0,
      li: {
        bg: 'backgroundGray',
        color: 'text',
        borderRadius: 'small',
        py: 4,
        px: [8, 16],
        mb: 8,
        mr: 8,
      },
    },
  },
};

export default theme;
