import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import useCanonical from 'hooks/use-canonical';

export default function DefaultMetadata() {
  const canonicalUrl = useCanonical();

  const defaults: DefaultSeoProps = {
    title: 'Standard Resume',
    canonical: canonicalUrl,
    openGraph: {
      type: 'website',
      locale: 'en_US',
      site_name: 'Standard Resume',
      images: [
        {
          url: `https://assets.standardresume.co/image/upload/v1615144042/share-cards/standard-resume-default_nett91.png`,
        },
      ],
    },
    twitter: {
      site: '@standardresume',
      cardType: 'summary_large_image',
    },
  };

  return <DefaultSeo {...defaults} />;
}
